/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import { BACKGROUND_COLOR } from "../colors"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <div 
      style={{
        backgroundColor: BACKGROUND_COLOR,
        height: '100vh',
        minHeight: '100vh',
      }}>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1024,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <Header />
        <main>{children}</main>
        <div style={{height: '200px'}} />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
