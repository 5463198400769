import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import IMG from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
  query Header {
    file(relativePath: {eq: "icon.png"}) {
      childImageSharp {
        fixed(height: 89, width: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return (
    <header style={{padding: `33px 0`}}>
      <IMG fixed={data.file.childImageSharp.fixed} />
    </header>
  )
}

export default Header;